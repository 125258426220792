<template>
  <div class="mt-2 wi100 hi100" v-loading="loading">
    <div class="content-center m-4" style="margin-right: 1.5rem">
      <div class="wi80">
        <el-card class="mt-3" shadow="never">
          <div class="p-3">
            <el-page-header @back="goBack">
              <span slot="content" class="fwb gb-default-color">产品质量安全监测</span>
            </el-page-header>
          </div>
          <el-descriptions :labelStyle="labelStyle" :contentStyle="contentStyle" :columns="3" size="small" border>
            <el-descriptions-item :span="3">
              <span slot="label">公司名称</span>
              <div class="uf">
                <div class="mt-1" style="margin: auto 0">
                  <el-avatar shape="circle" size="large" :src="enterpriseInfo.imageUrl" type="round"></el-avatar>
                </div>
                <div class="ml-1">
                  <div class=" f20"><span>{{ enterpriseInfo.name }}</span> <el-tag size="mini">{{enterpriseInfo.status}}</el-tag></div>
                  <div class="f14 pl-2 mt-2" style="color: #909399">
                    <span>{{enterpriseInfo.englishName}}</span>
                    <el-divider direction="vertical"></el-divider>
                    <span>官网：{{enterpriseInfo.websiteUrl}}</span>
                  </div>
                </div>
              </div>
            </el-descriptions-item>

            <el-descriptions-item>
              <span slot="label">企业类型</span>
            {{enterpriseInfo.qccIndustryEntity && enterpriseInfo.qccIndustryEntity.industry}}
            </el-descriptions-item>
            <el-descriptions-item :span="2">
              <span slot="label">企业地址</span>
             {{enterpriseInfo.address}}
            </el-descriptions-item>
            <el-descriptions-item :span="3">
              <span slot="label">经营范围</span>
              {{enterpriseInfo.scope}}
            </el-descriptions-item>
            <el-descriptions-item>
              <span slot="label">产品描述</span>
              {{productInfo.domain}}
            </el-descriptions-item>
            <el-descriptions-item :span="2">
              <span slot="label">产品范围</span>
              {{productInfo.tags}}
            </el-descriptions-item>
            <el-descriptions-item :span="3">
              <span slot="label">企业描述</span>
              {{productInfo.description}}
            </el-descriptions-item>
          </el-descriptions>
          <el-card style="width: 100%;height: 800px;text-align: center">
            <span style="font-size: 24px;font-weight: bold;margin-bottom: 30px">
              舆论占比
            </span>
            <el-row>
              <el-col :span="8">
                <div id="positiveChart" style="width: 100%;height: 200px"></div>
              </el-col>
              <el-col :span="8">
                <div id="noneChart" style="height: 200px;width: 100%"></div>
              </el-col>
              <el-col :span="8">
                <div id="negativeChart" style="height: 200px; width: 100%"></div>
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row :gutter="20" style="margin-top: 40px">
              <el-col :span="12">
                <div class="p-2" style="width: 90%;height: 400px;text-align: center">
                  <news-line :impactList="impactList"></news-line>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="wi100" style="width: 90%;height: 400px;text-align: center">
                  <news-list :newsList="newsList"/>
                </div>
              </el-col>
            </el-row>
          </el-card>
        </el-card>
      </div>
    </div>
    <recommend-product ref="product"></recommend-product>
  </div>
</template>
<script>
  import {head} from 'lodash'
  import moment from 'moment'
  import RecommendProduct from '../../components/recommend-product'
  import NewsLine from '../../manage/modules/tech-analysis/components/news-line'
  import NewsList from './components/news-list-comp'
export default {
  name: 'recommend-result-word2vec',
  filters: {
    dateFilter (date) {
      return moment(date).format('yyyy-MM-DD')
    }
  },
  components: {
    NewsLine,
    NewsList,
    RecommendProduct
  },
  computed: {
    enterpriseList: {
      get() {
        return this.$store.state.user.info
      }
    },
    enterpriseId() {
      // lodash的_.head方法取第一个企业信息
      let defaultEnterpriseInfo = head(this.enterpriseList)
      return defaultEnterpriseInfo ? defaultEnterpriseInfo.id : ''
    }
  },
  data() {
    return {
      loading: false,
      enterpriseInfo: {},
      productInfo: {},
      ratio: {},
      posInstance: {},
      negInstance: {},
      noneInstance: {},
      tagList: [],
      // 舆情新闻
      newsList: [],
      // 舆情情感统计
      newsImpactList: [],
      impactList: [],
      labelStyle: {
        width: '350px',
        textAlign: 'center'
      },
      contentStyle: {
        // width: '200px',
        // textAlign: 'center'
      }
    }
  },
  mounted() {
    this.initRecommendProduct()
    this.initNewsRatio()
  },
  created() {
    this.initBasicInfo()
    this.initProductInfo()
    this.initNews()
  },
  watch: {
    'ratio.noneRatio' (val) {
      if (val) {
        this.drawNoneChart()
      }
    }
  },
  methods: {
    // 基本信息
    initBasicInfo() {
      this.$http({
        url: this.$http.adornUrl(this.$api.QCC.ENT_INFO.INFO),
        method: 'GET',
        params: this.$http.adornParams({
          enterpriseId: this.enterpriseId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.enterpriseInfo = data['result'] || {}
          this.tagList = this.enterpriseInfo['qccEntTagList'] || []
        }
        if (this.loadingCount) {
          this.loadingCount -=1
        }
      })
    },
    // 产品信息
    initProductInfo() {
      this.$http({
        url: this.$http.adornUrl(this.$api.QCC.ENT_PRODUCT.PRODUCT_LIST),
        method: 'GET',
        params: this.$http.adornParams({
          enterpriseId: this.enterpriseId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.productInfo = data.Result && data.Result.length > 0 ? data.Result[0] : {}
        }
      })
    },
    initRecommendProduct() {
      this.$nextTick(() => {
        this.$refs.product.init(this.enterpriseId)
      })
    },
    goBack() {
      this.$router.go(-1)
    },
    drawPositiveChart() {
      let posDom = document.getElementById('positiveChart')
      this.posInstance = this.echarts.init(posDom)
      let option = {
        series: [
          {
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            progress: {
              show: true,
              width: 8,
              roundCap: true
            },
            splitNumber: 4,
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 8
              }
            },
            axisLabel: {
              fontSize: 8,
              color: '#ffffff'
            },
            axisTick: {
              splitNumber: 2,
              lineStyle: {
                width: 2,
                color: '#999'
              }
            },
            // 指针
            pointer: {
              width: 4
            },
            detail: {
              valueAnimation: true,
              fontSize: 14,
              formatter: function (value) {
                return value.toFixed(0) + '%积极舆论'
              }
            },
            data: [
              {
                value: this.ratio.positiveRatio * 100,
                // name: '积极舆论占比',
                itemStyle: {
                  fontSize: 14,
                  color: '#2ed573'
                }
              }
            ]
          }
        ]
      }
      this.posInstance.setOption(option)
    },
    drawNoneChart() {
      let noneDom = document.getElementById('noneChart')
      this.noneInstance = this.echarts.init(noneDom)
      let option = {
        series: [
          {
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            progress: {
              show: true,
              width: 8,
              roundCap: true
            },
            splitNumber: 4,
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 8
              }
            },
            axisLabel: {
              fontSize: 8,
              color: '#ffffff'
            },
            // 指针
            pointer: {
              width: 4
            },
            axisTick: {
              splitNumber: 2,
              lineStyle: {
                width: 2,
                color: '#999'
              }
            },
            detail: {
              valueAnimation: true,
              fontSize: 14,
              formatter: function (value) {
                return value.toFixed(0) + '%中立舆论'
              }
            },
            data: [
              {
                value: this.ratio.noneRatio * 100,
                // name: '中立舆论占比',
                itemStyle: {
                  fontSize: 14,
                  color: '#409eff'
                }
              }
            ]
          }
        ]
      }
      this.noneInstance.setOption(option)
    },
    drawNegativeChart() {
      let negDom = document.getElementById('negativeChart')
      this.negInstance = this.echarts.init(negDom)
      let option = {
        series: [
          {
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            progress: {
              show: true,
              width: 8,
              roundCap: true
            },
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 8
              }
            },
            splitNumber: 4,
            axisLabel: {
              fontSize: 8,
              color: '#ffffff'
            },
            axisTick: {
              splitNumber: 2,
              lineStyle: {
                width: 2,
                color: '#999'
              }
            },
            // 指针
            pointer: {
              width: 4
            },
            detail: {
              valueAnimation: true,
              fontSize: 14,
              formatter: function (value) {
                return value.toFixed(0) + '%消极舆论'
              }
            },
            data: [
              {
                value: this.ratio.negativeRatio * 100,
                // name: '消极舆论占比',
                itemStyle: {
                  fontSize: 14,
                  color: '#ff6348'
                }
              }
            ]
          }
        ]
      }
      this.negInstance.setOption(option)
    },
    /**
     * 初始化消极评论占比
     */
    initNewsRatio() {
      // this.$http({
      //   url: this.$http.adornUrl(this.$api.QCC.NEWS.RATIO),
      //   method: 'GET',
      //   params: this.$http.adornParams({
      //     enterpriseId: this.enterpriseId
      //   })
      // }).then(({data}) => {
      //   if (data && data.code === 0) {
      //     this.ratio = data['result']
      //     this.$nextTick(() => {
      //       this.drawPositiveChart()
      //       this.drawNoneChart()
      //       this.drawNegativeChart()
      //     })
      //   }
      // })
    },
    /**
     * 初始化舆情信息
     */
    initNews () {
      // this.loading = true
      // this.$http({
      //   url: this.$http.adornUrl(this.$api.QCC.NEWS.LIST),
      //   method: 'GET',
      //   params: this.$http.adornParams({
      //     enterpriseId: this.enterpriseId
      //   })
      // }).then(({data}) => {
      //   if (data && data.code === 0) {
      //     this.newsList = [...data['result']['news']]
      //     this.impactList = data['result']['impact'] || []
      //   }
      //   this.loading = false
      // })
    }
  }
}
</script>
<style scoped>

</style>
